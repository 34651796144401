<script>
	import { setContext } from "svelte";

	import draftsStore from "../../stores/data/draftsStore.js";
	import currentDraftIdStore from "../../stores/currentDraftIdStore.js";
	import layoutStore from "../../stores/layoutStore.js";

	import BbCodeManager from "../../bbCode/BbCodeManager.js";

	import PostBar from "./postBar/PostBar.svelte";
	import PostInput from "./PostInput.svelte";

	let postInput;
	let disablePost = false;
	let currentDraftId;
	let showTextarea = true;
	let showPreview = true;

	$: drafts = $draftsStore;

	let draft = {
		id: "new",
		text: "",
	};

	$: if (!draft?.text || draft.text.length === 0) disablePost = true;

	$: handleCurrentDraftIdStoreChange($currentDraftIdStore);
	$: handleDraftIdChange(draft.id);

	export function insertBbCode(bbCode) {
		postInput.insertBbCode(bbCode);
	}

	function handleDraftIdChange(draftId) {
		if (!draftId || draftId === currentDraftId) return;
		currentDraftId = draftId;
		disablePost = draft.text.length === 0;
	}

	function handleCurrentDraftIdStoreChange({ id: draftId, method }) {
		if (draftId === draft.id || !method) return;
		if (method === "cleared") {
			draft = {
				id: "new",
				text: "",
			};
			return;
		}
		if (method === "selected") {
			const selectedDraft = drafts.find((d) => d.id === draftId);
			if (!selectedDraft) return;
			draft = JSON.parse(JSON.stringify(selectedDraft));
			return;
		}
		if (method === "created") {
			draft = {
				...draft,
				id: draftId,
			};
		}
	}

	const bbCodeManager = new BbCodeManager(
		"b",
		"i",
		"u",
		"s",
		"datetime",
		"timediff",
		"quote",
		"link",
		"font",
		"hr",
		"pre",
		"code",
		"ul",
		"ol",
		"li"
	);
	setContext("bbCodeManager", bbCodeManager);

	const bottomPanelPositionStore = layoutStore.bottomPanelPositionStore;

	$: marginLeft = $layoutStore.sideWidth;
	$: marginRight = $layoutStore.sideWidth + $layoutStore.scrollbarWidth;
	$: margins = 2 * $layoutStore.sideWidth;
	$: height = $layoutStore.bottomBarHeight + $layoutStore.bottomPanelHeight;

	$: style = `margin-left: ${marginLeft}px; margin-right: ${marginRight}px; width: calc(100% - ${margins}px);`;

	$: divStyle = `${style} position: fixed; bottom: ${$bottomPanelPositionStore + $layoutStore.topAndBottomMargins}px; height: ${height}px;`;

	$: barStyle = `height: ${$layoutStore.bottomBarHeight}px;`;
	$: panelStyle = `height: ${$layoutStore.bottomPanelHeight}px;`;
</script>

<div style={divStyle}>
	<PostBar
		style={barStyle}
		{draft}
		{disablePost}
		bind:showTextarea
		bind:showPreview
		on:focusTextarea={() => postInput.focusTextarea()}
	/>
	<div style={panelStyle}>
		<div class="border-2 border-black h-full">
			<PostInput
				bind:this={postInput}
				bind:draft
				bind:disablePost
				{showTextarea}
				{showPreview}
			/>
		</div>
	</div>
</div>
