<script>
	import { getContext } from "svelte";

	import Display from "../../shared/Display.svelte";
	import Transition from "../../shared/Transition.svelte";
	import Button from "../../../tailwind/Button.svelte";
</script>

<Display title="Information">
	<Transition>
		<div>
			<Button extraClasses="my-1 w-full" path="acknowledgements">
				Acknowledgements
			</Button>
		</div>
	</Transition>
</Display>
