<script>
	import DefaultAvatar from "./defaults/DefaultAvatar.svelte";
	import Image from "../Image.svelte";

	export let value = null;
	export let user = null;
	export let size = "large";
	export let round = false;
	export let avatar = null;

	$: avatarToShow = value || avatar || user?.profile?.avatar;

	$: sizeClass = {
		large: "w-24 lg:w-28",
		medium: "w-16",
	}[size];

	$: roundedClass = round ? "rounded-full" : "rounded";

	$: avatarType =
		avatarToShow && avatarToShow.startsWith("default") ? "default" : "image";
</script>

<div class="avatar">
	<div class="{sizeClass} {roundedClass}">
		{#if avatarType === "image"}
			<Image src={avatarToShow} />
		{:else if avatarType === "default"}
			<DefaultAvatar avatar={avatarToShow} />
		{/if}
	</div>
</div>
