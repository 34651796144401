<script>
	import currentUserStore from "../../../../stores/data/currentUserStore.js";

	import FileInput from "./FileInput.svelte";
	import Avatar from "../../../shared/avatar/Avatar.svelte";

	export let field;
	export let error;

	let preview;

	$: setInitialAvatar($currentUserStore);

	function setInitialAvatar(currentUser) {
		if (preview) return;
		if (!currentUser) return;
		preview = currentUser.profile?.avatar;
	}

	function onFileSelected(e) {
		const file = e.target.files[0];
		field.value = file;
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (e) => (preview = e.target.result);
	}
</script>

<FileInput bind:field {error} on:change={onFileSelected} />

<Avatar value={preview} />
