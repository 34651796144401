<script>
	import { setContext, onDestroy } from "svelte";

	import currentUserStore from "../../../../stores/data/currentUserStore";
	import paramsStore from "../../../../stores/paramsStore";
	import topicsStore from "../../../../stores/data/topicsStore";
	import itemsCountStore from "../../../../stores/itemsCountStore";
	import scrollStore from "../../../../stores/scrollStore";

	import topicChannel from "../../../../channels/topicChannel.js";

	import BbCodeManager from "../../../../bbCode/BbCodeManager";

	import PostsWrapper from "../../../shared/PostsWrapper.svelte";
	import Post from "../post/Post.svelte";
	import Fetcher from "../../../../api/Fetcher";

	$: forumSlug = $paramsStore.domainSlug;
	$: sectionSlug = $paramsStore.sectionSlug;
	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[topicSlug];
	$: pageNumber =
		parseInt($paramsStore.pageNumber) ||
		$paramsStore.pageCount ||
		$paramsStore.pageNumber;
	$: pageSize = $currentUserStore.settings?.pageSize || 50;
	$: offset = pageSize * (pageNumber - 1) || 0;
	$: posts = topic?.posts?.slice(offset, offset + pageSize) || [];

	let lastViewedPostNumber = 0;
	let updateLastViewedPostNumber = false;
	let debounceTimer;
	let channel;

	$: handlePageNumberChange(pageNumber);

	$: itemsCountStore.set(topic?.postCount || 0);

	$: handleSlugsChange({ forumSlug, sectionSlug, topicSlug });

	function handleSlugsChange(slugs) {
		topicsStore.fetchTopic(slugs);
		channel?.unsubscribe();
		channel = topicChannel(slugs.topicSlug);
	}

	function handlePageNumberChange(pageNumber) {
		if (posts.length === 0 || posts.includes(undefined)) {
			topicsStore.fetchPostsPage({
				forumSlug,
				sectionSlug,
				topicSlug,
				pageNumber,
			});
		}
	}

	onDestroy(handleDestroy);

	function handleDestroy() {
		channel?.unsubscribe();
	}

	const bbCodeManager = new BbCodeManager(
		"b",
		"i",
		"u",
		"s",
		"datetime",
		"timediff",
		"quote",
		"link",
		"font",
		"hr",
		"pre",
		"code",
		"ul",
		"ol",
		"li"
	);
	setContext("bbCodeManager", bbCodeManager);

	$: checkScrollStore($scrollStore, posts);
	$: handleTopicChange(topic);

	function checkScrollStore(scroll, posts, depth = 0) {
		if (depth > 100) return;
		if (!scroll) return;
		if (scroll.location === "top") {
			scrollStore.clear();
			return window.scrollTo(0, 0);
		}
		const post = posts.find((p) => p?.tag === scroll.id);
		if (!post) return;
		const element = document.getElementById(scroll.id);
		if (!element) {
			setTimeout(() => checkScrollStore(scroll, posts, depth + 1), 200);
			return;
		}
		scrollStore.clear();
		element.scrollIntoView({ behavior: "smooth" });
	}

	function handleTopicChange(topic) {
		const lvpn = topic?.userData?.lastViewedPostNumber;
		updateLastViewedPostNumber = lvpn != null;
		if (lvpn > lastViewedPostNumber) {
			lastViewedPostNumber = lvpn;
		}
	}

	function handlePostInView(e) {
		if (!updateLastViewedPostNumber) return;
		const number = e.detail.number;
		if (number > lastViewedPostNumber) {
			lastViewedPostNumber = number;
			clearTimeout(debounceTimer);
			debounceTimer = setTimeout(
				() => Fetcher.updateUserTopic({ topic, lastViewedPostNumber }),
				3000
			);
		}
	}
</script>

<PostsWrapper>
	{#each posts.filter(Boolean) as post (post.id)}
		{#if !post.spam || topic.title === "__spam__"}
			<Post {post} on:inView={handlePostInView} />
		{/if}
	{/each}
</PostsWrapper>
