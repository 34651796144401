<script>
	import currentUserStore from "../../stores/data/currentUserStore.js";
	import nowStore from "../../stores/nowStore.js";

	import Badge from "../tailwind/Badge.svelte";
	import Link from "../tailwind/Link.svelte";

	import DateTime from "../../util/DateTime.js";

	export let time;
	export let reversed = false;
	export let path = null;
	export let clickable = false;

	$: timeZone = $currentUserStore.profile.timeZone;
	$: dataTip = (time && DateTime.timeDifference(time, $nowStore)) || "";
	$: text = DateTime.dtStringFromIso(time, timeZone);

	$: Component = path || clickable ? Link : Badge;
</script>

<svelte:component
	this={Component}
	{path}
	{...clickable ? { clickable } : {}}
	tooltip={reversed ? text : dataTip}
	on:click
>
	{reversed ? dataTip : text}
</svelte:component>
