import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import fetchCurrentUser from "../api/fetchCurrentUser.js";

class EditProfileForm extends Form {
	static title = "Edit Profile";
	static code = "edit-profile";
	static submitValue = "Save";

	static initialFields({ currentUser }) {
		return [
			{
				type: "input",
				inputType: "avatar",
				value: null,
				label: "Avatar",
				id: "profile-avatar",
			},
			{
				type: "input",
				inputType: "timeZone",
				value: currentUser.profile.timeZone,
				label: "Time Zone (type to search)",
				id: "profile-time-zone",
				placeholder: "UTC",
				required: true,
			},
			{
				type: "checkbox",
				value: currentUser.profile.showTimeZone,
				label: "Show Time Zone on profile",
				id: "profile-show-time-zone",
			},
		];
	}

	static async submit({ fields }) {
		const avatar = fields[0].value;
		const timeZone = fields[1].value;
		const showTimeZone = fields[2].value;
		return Fetcher.updateProfile({
			avatar,
			timeZone,
			showTimeZone,
		});
	}

	static handleSuccess = fetchCurrentUser;
}

export default EditProfileForm;
