<script>
	import { createEventDispatcher } from "svelte";

	import PostInfo from "./PostInfo.svelte";
	import PostContent from "./PostContent.svelte";
	import PostText from "../../../postBox/PostText.svelte";
	import InView from "../../../shared/InView.svelte";

	export let post;

	let inView = false;

	const dispatch = createEventDispatcher();

	$: handleInViewChange(inView);

	function handleInViewChange(inView) {
		if (inView) {
			dispatch("inView", { number: post.number });
		}
	}
</script>

<div class="flex flex-col">
	<div
		class="grow bg-base-100 flex flex-col sm:flex-row gap-4 py-4 px-6 rounded-xl"
	>
		<div class="w-24 lg:w-32 flex flex-col">
			<PostInfo {post} />
			<div></div>
		</div>
		<div class="grow">
			<PostContent {post}>
				<PostText text={post.text} />
			</PostContent>
		</div>
	</div>
	<InView bind:inView />
</div>
