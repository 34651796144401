import { writable } from "svelte/store";

const { subscribe, update } = writable({
	default: "light",
	settings: null,
	temporary: null,
});

function setSettings(theme) {
	if (!theme) return;
	update((current) => ({
		...current,
		settings: theme,
	}));
}

function setTemporary(theme) {
	update((current) => ({
		...current,
		temporary: theme,
	}));
}

export default { subscribe, setSettings, setTemporary };
