<script>
	import TimeStamp from "../../../shared/TimeStamp.svelte";
	import PostOptions from "./PostOptions.svelte";

	export let post;
	export let showOptions = true;
</script>

<div class="flex flex-col gap-2">
	<div id={post.tag} class="flex flex-row flex-wrap gap-2 scroll-mt-20">
		{#if post.tag}
			<span class="badge badge-outline bg-base-100">#{post.tag}</span>
		{/if}
		<TimeStamp time={post.createdAt} />
		<div class="flex-grow"></div>
		{#if showOptions}
			<div>
				<PostOptions {post} />
			</div>
		{/if}
	</div>
	<slot />
	{#if post.note && typeof post.note === "string"}
		<div>Note: {post.note}</div>
	{/if}
</div>
