<script>
	import { onMount, setContext } from "svelte";
	import uuid from "../util/uuid.js";

	import currentUserStore from "../stores/data/currentUserStore.js";
	import usersDataStore from "../stores/data/usersDataStore.js";
	import notificationsStore from "../stores/data/notificationsStore.js";
	import draftsStore from "../stores/data/draftsStore.js";
	import bookmarksStore from "../stores/data/bookmarksStore.js";

	import fetchCurrentUser from "../api/fetchCurrentUser.js";

	import userChannel from "../channels/userChannel.js";

	import Page from "./Page.svelte";

	import layoutStore from "../stores/layoutStore.js";
	import themeStore from "../stores/themeStore.js";

	let postBox;

	setContext("insertBbCode", (bbCode) => postBox.insertBbCode(bbCode));

	$: theme =
		$themeStore.temporary || $themeStore.settings || $themeStore.default;

	const sessionId = uuid();

	setContext("sessionId", sessionId);

	let channel;
	let currentUserSlug;

	onMount(handleMount);

	function handleMount() {
		fetchCurrentUser();
		usersDataStore.fetchUsersData();
		notificationsStore.fetchNotifications();
		draftsStore.fetchDrafts();
		bookmarksStore.fetchBookmarks();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}

	$: handleCurrentUserSlugChange($currentUserStore.slug);

	function handleCurrentUserSlugChange(newUserSlug) {
		if (!newUserSlug || newUserSlug === currentUserSlug) return;
		currentUserSlug = newUserSlug;
		channel?.unsubscribe();
		channel = userChannel(currentUserSlug);
	}

	function handleResize() {
		layoutStore.updateScreenWidth(window.innerWidth);
	}
</script>

<div data-theme={theme} class="h-full">
	{#if $currentUserStore.slug}
		<Page bind:postBox />
	{/if}
</div>

<style global lang="postcss">
	@tailwind base;
	@tailwind components;
	/* purgecss end ignore */
	@tailwind utilities;
</style>
