import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import currentUserStore from "../stores/data/currentUserStore.js";

class EditSettingsForm extends Form {
	static title = "Edit Settings";
	static code = "edit-settings";
	static submitValue = "Save";

	static initialFields({ currentUser }) {
		return [
			{
				type: "input",
				inputType: "number",
				value: currentUser.settings.pageSize,
				label: "Page size",
				id: "settings-page-size",
				min: 1,
				max: 100,
			},
			{
				type: "input",
				inputType: "theme",
				value: currentUser.settings.theme,
				label: "Theme",
				id: "settings-theme",
			},
			{
				type: "input",
				inputType: "number",
				value: currentUser.settings.quoteDepth,
				label: "Depth to auto-show quotes",
				id: "settings-quote-depth",
				min: 0,
			},
		];
	}

	static async submit({ fields }) {
		const pageSize = fields[0].value;
		const theme = fields[1].value;
		const quoteDepth = fields[2].value;
		return Fetcher.updateSettings({ pageSize, theme, quoteDepth });
	}

	static handleSuccess(json) {
		currentUserStore.updateCurrentUser(json);
	}
}

export default EditSettingsForm;
