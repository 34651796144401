<script>
	import currentUserStore from "../../../stores/data/currentUserStore.js";
	import paramsStore from "../../../stores/paramsStore.js";
	import topicsStore from "../../../stores/data/topicsStore.js";
	import currentDraftIdStore from "../../../stores/currentDraftIdStore.js";
	import layoutStore from "../../../stores/layoutStore.js";

	import DateTime from "../../../util/DateTime.js";

	import { redirectToTopic } from "../../../util/redirectTo.js";

	import Fetcher from "../../../api/Fetcher.js";

	import Button from "../../tailwind/Button.svelte";
	import Datetime from "../../../bbCode/components/Datetime.svelte";

	export let draft;
	export let disabled;

	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[topicSlug];
	$: pageSize = $currentUserStore.settings?.pageSize;

	async function setPostTag(json) {
		redirectToTopic({
			topic,
			pageSize,
			postNumber: json.number,
		});
	}

	function handleClick() {
		if (!topic || !draft) return;
		Fetcher.createTopicIntent({ topic }).then(({ intentId }) => {
			if (!intentId) return;
			Fetcher.createPost({ topic, draft, intentId }).then(
				handleCreatePostResponse
			);
		});
	}

	function handleCreatePostResponse(json) {
		if (!json.id) {
			return;
		}
		setPostTag(json)
			.then(currentDraftIdStore.clear)
			.then(layoutStore.toggleBottomPanel);
	}
</script>

<Button
	className="success"
	extraClasses="btn-xs"
	{disabled}
	on:click={handleClick}
>
	Post
</Button>
