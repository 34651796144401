import redirect from "./redirect";
import Fetcher from "../api/Fetcher";

export function redirectToTopic({
	topic,
	pageSize,
	postNumber,
	forumSlug,
	sectionSlug,
	topicSlug,
}) {
	let tag = "";
	let pageNumber = 1;
	if (topic) {
		forumSlug = topic.forumSlug;
		sectionSlug = topic.sectionSlug;
		topicSlug = topic.slug;
	}
	if (postNumber) {
		if (postNumber === "last") {
			postNumber = topic.postCount;
		}
		pageNumber = Math.floor((postNumber - 1) / pageSize + 1);
		tag = `#${postNumber}`;
	}
	if (!forumSlug && !sectionSlug && !topicSlug) return;
	redirectTo({
		forumSlug,
		sectionSlug,
		topicSlug,
		pageNumber,
		tag,
	});
}

export function redirectTo({
	forumSlug,
	sectionSlug,
	topicSlug,
	pageNumber = 1,
	tag,
}) {
	if (topicSlug && !(forumSlug && sectionSlug)) {
		return findAndRedirectToTopic({ topicSlug, pageNumber, tag });
	}
	if (sectionSlug && !forumSlug)
		return findAndRedirectToSection({ sectionSlug });
	if (!sectionSlug) return redirect(`${forumSlug}/forum`);
	if (!topicSlug) return redirect(`${forumSlug}/forum/${sectionSlug}/p/1`);
	const url = `${forumSlug}/forum/${sectionSlug}/${topicSlug}/p/${pageNumber}${tag}`;
	redirect(url);
}

function findAndRedirectToTopic({ topicSlug, pageNumber, tag }) {
	Fetcher.findTopic({ topicSlug }).then((json) => {
		const { forumSlug, sectionSlug } = json;
		if (forumSlug && sectionSlug)
			redirectTo({ forumSlug, sectionSlug, topicSlug, pageNumber, tag });
	});
}

function findAndRedirectToSection({ sectionSlug }) {
	Fetcher.findSection({ sectionSlug }).then((json) => {
		const { forumSlug } = json;
		if (forumSlug) {
			redirectTo({ forumSlug, sectionSlug });
		}
	});
}
