import { writable } from "svelte/store";

const { subscribe, set, update } = writable({ id: "new" });

function clear() {
	set({
		id: "new",
		method: "cleared",
	});
}

function clearIfCurrent(id) {
	update((current) => {
		if (current.id !== id) return current;
		return {
			id: "new",
			method: "cleared",
		};
	});
}

function select(id) {
	set({
		id,
		method: "selected",
	});
}

function created(id) {
	set({
		id,
		method: "created",
	});
}

export default { subscribe, clear, clearIfCurrent, select, created };
