<script>
	import currentDraftIdStore from "../../../../stores/currentDraftIdStore.js";
	import layoutStore from "../../../../stores/layoutStore.js";

	import Fetcher from "../../../../api/Fetcher";
	import fetchCurrentUser from "../../../../api/fetchCurrentUser.js";
	import Icon from "../../../icons/small/Icon.svelte";

	export let draft;

	function selectDraft() {
		currentDraftIdStore.select(draft.id);
		layoutStore.openBottomPanel();
	}

	function deleteDraft() {
		Fetcher.deleteDraft({ draft }).then(handleDraftDeleted);
	}

	function handleDraftDeleted() {
		currentDraftIdStore.clearIfCurrent(draft.id);
		fetchCurrentUser();
	}
</script>

<div class="bg-base-300 py-2 px-4 rounded-lg flex gap-2 cursor-pointer">
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div
		class="grow overflow-hidden"
		style="text-overflow: ellipsis; white-space: nowrap;"
		on:click={selectDraft}
	>
		{draft.text}
	</div>
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div class="my-auto text-error" on:click={deleteDraft}>
		<Icon name="bin" />
	</div>
</div>
