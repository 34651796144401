<script>
	import currentUserStore from "../../stores/data/currentUserStore.js";
	import nowStore from "../../stores/nowStore.js";

	import DateTime from "../../util/DateTime.js";
	import { redirectToTopic } from "../../util/redirectTo.js";
	import Icon from "../../components/icons/large/Icon.svelte";

	export let author;
	export let tag;
	export let datetime;
	export let forumSlug;
	export let sectionSlug;
	export let topicSlug;
	export let show;

	$: currentUser = $currentUserStore;
	$: pageSize = currentUser?.settings?.pageSize || 50;
	$: timeZone = currentUser.profile?.timeZone || "UTC";

	$: isLink = !!topicSlug;

	$: text = getText(author, datetime, timeZone);
	$: tooltip =
		(datetime && DateTime.timeDifference(datetime, $nowStore)) || null;

	function getText(author, datetime, timeZone) {
		let result = "Quote";
		if (author) result += ` by ${author}`;
		if (datetime) {
			result += ` on ${DateTime.dtStringFromIso(datetime, timeZone)}`;
		}
		return result;
	}

	function handleClick() {
		redirectToTopic({
			forumSlug,
			sectionSlug,
			topicSlug,
			postNumber: tag,
			pageSize,
		});
	}
</script>

<div class="flex">
	{#if isLink}
		<!-- svelte-ignore a11y-click-events-have-key-events -->
		<div
			class="link text-sm z-50"
			on:click={handleClick}
			class:tooltip
			data-tip={tooltip}
		>
			{text}
		</div>
	{:else}
		<div class="text-sm z-50" class:tooltip data-tip={tooltip}>
			{text}
		</div>
	{/if}
	<div class="grow"></div>
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div class="cursor-pointer" on:click={() => (show = !show)}>
		<Icon name={!show ? "chevronDown" : "chevronUp"} />
	</div>
</div>
