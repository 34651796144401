<script>
	import { createEventDispatcher } from "svelte";

	import currentDraftIdStore from "../../../stores/currentDraftIdStore";

	import Button from "../../tailwind/Button.svelte";

	// export let draft;
	export let disabled;

	const dispatch = createEventDispatcher();

	function handleClick() {
		currentDraftIdStore.clear();
		dispatch("focusTextarea");
	}
</script>

<Button
	className="warning"
	extraClasses="btn-xs"
	{disabled}
	on:click={handleClick}
>
	Clear
</Button>
