<script>
	import UserBadge from "../../../shared/UserBadge.svelte";

	export let post;

	import Avatar from "../../../shared/avatar/Avatar.svelte";
</script>

<div class="flex flex-col">
	<div class="mx-auto max-w-full">
		<UserBadge user={post.user} />
	</div>
	<div class="mx-auto flex">
		<Avatar value={post.user?.profile?.avatar} />
	</div>
</div>
