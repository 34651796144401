<script>
	import { getContext, tick } from "svelte";

	import layoutStore from "../../stores/layoutStore.js";
	import currentDraftIdStore from "../../stores/currentDraftIdStore.js";

	import Fetcher from "../../api/Fetcher.js";
	import fetchCurrentUser from "../../api/fetchCurrentUser.js";
	import BbCodeInsertion from "../../bbCode/BbCodeInsertion.js";
	import Actions from "./Actions.svelte";
	import Preview from "./Preview.svelte";

	export let draft;
	export let disablePost;
	export let showTextarea;
	export let showPreview;

	let textarea;
	let text = "";
	let debounceTimer;
	let currentDraftId;
	let hideTextarea = false;
	let hidePreview = false;
	let selectionStart;
	let selectionEnd;

	const bbCodeManager = getContext("bbCodeManager");

	let insertions = {};
	bbCodeManager.bbCodes
		.map((bbCode) => bbCode.code)
		.forEach((code) => (insertions[code] = newInsertion(code)));

	$: handleDraftIdChange(draft);
	$: handleBottomPanelOpenChange($layoutStore.bottomPanelOpen);

	$: handleShowTextareaChange(showTextarea);
	$: handleShowPreviewChange(showPreview);

	export async function focusTextarea() {
		await tick();
		if (selectionStart !== undefined && selectionEnd !== undefined) {
			textarea.setSelectionRange(selectionStart, selectionEnd);
			selectionStart = undefined;
			selectionEnd = undefined;
		}
		textarea.focus();
	}

	function handleShowTextareaChange(showTextarea) {
		if (!showTextarea) {
			selectionStart = textarea.selectionStart;
			selectionEnd = textarea.selectionEnd;
		}
		hideTextarea = !showTextarea;
	}

	function handleShowPreviewChange(showPreview) {
		if (textarea) {
			selectionStart = textarea.selectionStart;
			selectionEnd = textarea.selectionEnd;
		}
		hidePreview = !showPreview;
		focusTextarea();
	}

	function handleDraftIdChange(draft) {
		if (!draft.id || draft.id === currentDraftId) return;
		text = draft.text;
		currentDraftId = draft.id;
	}

	function handleBottomPanelOpenChange(open) {
		open ? textarea?.focus() : textarea?.blur();
	}

	function newInsertion(code) {
		return new BbCodeInsertion(`[${code}]$1[/${code}]`);
	}

	async function onKeyDown(e) {
		if (e.key === "Escape" && $layoutStore.bottomPanelOpen) {
			layoutStore.closeBottomPanel();
			return;
		}
		if (!e.ctrlKey) return;
		if (e.key === "q") {
			e.preventDefault();
			layoutStore.toggleBottomPanel();
			return;
		}
		if (!$layoutStore.bottomPanelOpen) return;
		const bbCode = bbCodeManager.bbCodes.find(
			(bbc) => bbc.keyCode === e.keyCode
		);
		if (!bbCode) return;
		e.preventDefault();
		insert(bbCode.code);
	}

	function handleTextChanged(text) {
		if (text === draft.text) return;
		draft.text = text;
		disablePost = true;
		clearTimeout(debounceTimer);
		debounceTimer = setTimeout(() => updateDraft(text), 250);
	}

	function updateDraft(text) {
		if (draft.id === "new") {
			if (text.length === 0) return;
			draft.id = "saving";
			Fetcher.createDraft({ text }).then(handleDraftCreated);
		} else if (draft.id !== "saving") {
			Fetcher.updateDraft({ draft, text }).then(handleDraftUpdated);
		}
	}

	function handleDraftCreated(json) {
		// draft.id = json.id;
		disablePost = false;
		currentDraftIdStore.created(json.id);
		fetchCurrentUser();
	}

	function handleDraftUpdated() {
		disablePost = false;
		fetchCurrentUser();
	}

	export async function insert(code) {
		const { newText, newSelectionRange } = bbCodeManager.insert(
			draft.text,
			textarea.selectionStart,
			textarea.selectionEnd,
			code
		);
		text = newText;
		handleTextChanged(text);
		await tick();
		textarea.setSelectionRange(...newSelectionRange);
		textarea.focus();
	}

	export async function insertBbCode(bbCode) {
		layoutStore.openBottomPanel();
		const selectionStart = textarea.selectionStart;
		const newSelectionStart = selectionStart + bbCode.length;
		text = text.slice(0, selectionStart) + bbCode + text.slice(selectionStart);
		handleTextChanged(text);
		await tick();
		textarea.focus();
		textarea.setSelectionRange(newSelectionStart, newSelectionStart);
	}
</script>

<svelte:window on:keydown={onKeyDown} />

<div class="p-2 flex flex-col gap-2 h-full bg-base-300">
	<div class="grid gap-4" class:grid-cols-2={!hideTextarea && !hidePreview}>
		{#if !hideTextarea}
			<Actions on:insert={(e) => insert(e.detail.code)} />
		{/if}
		{#if !hidePreview}
			<div><h1>Preview</h1></div>
		{/if}
	</div>
	<div
		class="grid gap-4"
		class:grid-cols-2={!hideTextarea && !hidePreview}
		style="height: 196px;"
	>
		{#if !hideTextarea}
			<div class="h-full">
				<textarea
					bind:this={textarea}
					id="post-input"
					class="w-full h-full textarea textarea-bordered resize-none text-sm"
					spellcheck="false"
					bind:value={text}
					on:keyup={() => handleTextChanged(text)}
				></textarea>
			</div>
		{/if}
		{#if !hidePreview}
			<Preview {text} />
		{/if}
	</div>
</div>
