<script>
	import resetDataStores from "../../../../../stores/data/resetDataStores.js";

	import fetchCurrentUser from "../../../../../api/fetchCurrentUser.js";
	import { clearJwt } from "../../../../../api/jwt.js";
	import redirect from "../../../../../util/redirect.js";

	import Button from "../../../../tailwind/Button.svelte";

	function handleClick() {
		resetDataStores();
		redirect("");
		clearJwt();
		setTimeout(() => {
			redirect("");
			fetchCurrentUser();
		}, 200);
	}
</script>

<Button
	id="logout-button"
	className="neutral"
	extraClasses="w-full"
	on:click={handleClick}
>
	Logout
</Button>
