import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import { redirectTo } from "../util/redirectTo.js";
import permissionsTab from "../components/shared/permissionsTabs/sectionPermissionsTab.js";

class NewSectionForm extends Form {
	static title = "New {sectionGroup.title} Section";
	static code = "new-{sectionGroup.title}-section";

	static initialPages({ forumSlug, sectionGroup }) {
		return [
			{
				tab: "Basic",
				fields: [
					{
						type: "input",
						inputType: "text",
						value: "",
						label: "Title",
						id: `new-${sectionGroup.title}-section-title`,
						placeholder: "Title",
						required: true,
					},
					{
						type: "input",
						inputType: "text",
						value: "",
						label: "Description",
						id: `new-${sectionGroup.title}-section-description`,
						placeholder: "Description",
						maxLength: 128,
					},
					{
						type: "input",
						inputType: "urlType",
						value: "fromTitle",
						label: "URL",
						id: `new-${sectionGroup.title}-section-url-type`,
						description:
							"A URL based off the title could cause another user to confirm the existence of a section with this title, even if they don't have access to it.",
					},
				],
			},
			permissionsTab({ sectionGroupPermissions: sectionGroup.permissions }),
			{ forumSlug, sectionGroup },
		];
	}

	static async submit({ pages }) {
		const title = pages[0].fields[0].value;
		const description = pages[0].fields[1].value;
		const urlType = pages[0].fields[2].value;
		const permissionsLevels = pages[1].fields[0].levels;
		const sectionPermissions = permissionsLevels[0].value;
		const topicsPermissions = permissionsLevels[1].value;
		const permissions = {
			...sectionPermissions,
			topics: topicsPermissions,
		};
		const { forumSlug, sectionGroup } = pages[2];
		return Fetcher.createSection({
			forumSlug,
			sectionGroup,
			title,
			description,
			urlType,
			permissions,
		});
	}

	static handleSuccess(json) {
		redirectTo({ forumSlug: json.forumSlug, sectionSlug: json.slug });
	}
}

export default NewSectionForm;
