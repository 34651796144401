<script>
	import { onDestroy } from "svelte";

	import paramsStore from "../../../stores/paramsStore.js";
	import domainsStore from "../../../stores/data/domainsStore.js";
	import forumsStore from "../../../stores/data/forumsStore.js";
	import editSectionsStore from "../../../stores/editSectionsStore.js";

	import redirect from "../../../util/redirect.js";
	import forumChannel from "../../../channels/forumChannel.js";

	import Link from "../../tailwind/Link.svelte";
	import SectionGroup from "./sectionGroup/SectionGroup.svelte";
	import SectionGroupsAndSectionsEditor from "./sectionGroupsAndSectionsEditor/SectionGroupsAndSectionsEditor.svelte";

	$: forumSlug = $paramsStore.domainSlug;
	$: domain = $domainsStore[forumSlug];
	$: forum = $forumsStore[forumSlug];
	$: sectionGroupIds = forum?.sectionGroupIds || [];

	$: allTopicsPath = getAllTopicsPath(forum);

	$: if (forum && forum.success === false) redirect("/");

	let subscription = {};

	$: editSections = domain && $editSectionsStore[forumSlug];

	$: handleForumSlugChange(forumSlug);

	function handleForumSlugChange(forumSlug) {
		if (!forumSlug) return;
		forumsStore.fetchForum(forumSlug);
		forumsStore.fetchSectionGroups(forumSlug);
		forumsStore.fetchSectionsData(forumSlug);
	}

	$: manageSubscription(forumSlug, forum?.userAuthority);

	function manageSubscription(forumSlug, userAuthority) {
		if (
			!forumSlug ||
			userAuthority == null ||
			(forumSlug === subscription.forumSlug &&
				userAuthority === subscription.userAuthority)
		)
			return;
		subscription.forumSlug = forumSlug;
		subscription.userAuthority = userAuthority;
		subscription.channel?.unsubscribe();
		subscription.channel = forumChannel(forumSlug, forum.userAuthority || 0);
	}

	onDestroy(handleDestroy);

	function handleDestroy() {
		subscription.channel?.unsubscribe();
	}

	function getAllTopicsPath(forum) {
		if (!forum) return "/";
		return `${forumSlug}/forum/all/p/1`;
	}
</script>

{#if forumSlug || domain}
	<div class="flex flex-col gap-4">
		{#if editSections}
			<SectionGroupsAndSectionsEditor {editSections} />
		{:else}
			<div><Link path={allTopicsPath}>All Topics</Link></div>
			{#each sectionGroupIds as sectionGroupId (sectionGroupId)}
				<SectionGroup {editSections} {sectionGroupId} />
			{/each}
		{/if}
	</div>
{/if}
