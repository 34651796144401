<script>
	import DeleteButton from "./DeleteButton.svelte";
	import ClearButton from "./ClearButton.svelte";
	import PostButton from "./PostButton.svelte";
	import TopicLocked from "./TopicLocked.svelte";
	import PostUnlocker from "./PostUnlocker.svelte";

	export let topic;
	export let draft;
	export let disablePost;

	$: showButtons = draft?.text?.length > 0;
	$: canPost = topic?.userPermissions?.post;
	$: canUnlock = topic?.userPermissions?.postUnlock;
</script>

{#if showButtons}
	<div>
		<DeleteButton {draft} disabled={disablePost} on:focusTextarea />
	</div>
	<div>
		<ClearButton disabled={disablePost} on:focusTextarea />
	</div>
	{#if !topic || canPost}
		<div>
			<PostButton {draft} disabled={disablePost || !canPost} />
		</div>
	{/if}
{/if}

{#if topic && !canPost}
	{#if canUnlock}
		<PostUnlocker {topic} />
	{:else}
		<TopicLocked />
	{/if}
{/if}
